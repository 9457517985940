// Package Imports

// import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslate } from '@refinedev/core';

// Package Imports

import { useTokenContext } from 'contexts/resource/ResourceContext';
import { getFormattedDate } from 'core/utils/getDataValue';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  // InfoCircleOutlined,
} from '@ant-design/icons';

const Notification = ({ notificationsVisible }: any) => {
  const t = useTranslate();
  const { notificationMessages, setNotificationMessages } = useTokenContext();
  const [showReadMsg, setShowReadMsg] = useState(true);
  const [filteredNotificationMsgs, setFilteredNotificationMsgs] =
    useState(notificationMessages);
  const handleClear = () => {
    setNotificationMessages([]);
    if (localStorage.getItem('notifications') !== null) {
      localStorage.removeItem('notifications');
    }
  };
  const handleClose = () => {
    const updatedNotificationMsg = notificationMessages?.map((message) => ({
      ...message,
      read: true,
    }));
    setNotificationMessages(updatedNotificationMsg);
    localStorage.setItem(
      'notifications',
      JSON.stringify(updatedNotificationMsg)
    );
  };
  function handleMsgToggle() {
    setShowReadMsg(!showReadMsg);
  }
  useEffect(() => {
    if (notificationMessages) {
      if (showReadMsg) {
        setFilteredNotificationMsgs(notificationMessages);
      } else {
        const unreadMsgs =
          notificationMessages?.filter((msg) => !msg?.read) || [];
        setFilteredNotificationMsgs(unreadMsgs);
      }
    }
  }, [showReadMsg]);

  const getStatusIcon = (status) => {
    if (status === 'success') {
      return <CheckCircleOutlined className="text-green-500 text-2xl" />;
    }
    if (status === 'failed') {
      return <ExclamationCircleOutlined className="text-red-500 text-2xl" />;
    }
    return <InfoCircleOutlined className="text-primary-500 text-2xl" />;
  };

  return notificationsVisible ? (
    <div
      className="fixed top-[64px] p-6 bg-white drop-shadow-lg rounded-lg flex w-screen max-w-max -translate-x-1/2 notification"
      style={{
        // backgroundColor: '#f0f0f0',
        boxShadow:
          'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
      }}
    >
      <div className="notification-arrow" />
      <div className="w-screen max-w-md flex-auto  text-sm leading-6 shadow-lg ring-2 ring-gray-900/6 bg-gradient-to-r from-gray-50 to-blue-100">
        {/* <div> */}
        {/* show all msgs initially */}
        <span className="text-white bg-primary-400 px-4 py-1 rounded-md font-semibold">
          All{' '}
          <span className="bg-white text-black px-1 rounded-md ml-1">
            {filteredNotificationMsgs?.length}
          </span>
        </span>
        <div className="mt-4 mb-4">
          {' '}
          <span className="text-black text-base">
            {t('pages.socketNotification.readUnreadMsg')}
          </span>
          <Switch
            defaultChecked
            onChange={handleMsgToggle}
            className="mx-2.5"
          />
        </div>
        {filteredNotificationMsgs && filteredNotificationMsgs?.length > 0 ? (
          <div className="overflow-y-auto max-h-[600px] p-1">
            {filteredNotificationMsgs?.map((item, msgindex) => {
              const isSuccess = item.status === 'success';
              const isError = item.status === 'failed';
              const inProgress = item.status === 'in-progress';
              const started = item.status === 'started';

              return (
                <div
                  key={msgindex}
                  className={`group gap-x-6 p-4 mb-4 ${
                    isSuccess ? 'border-green-500 bg-green-50' : ''
                  } ${isError ? 'border-red-500 bg-red-50' : ''} ${
                    inProgress ? 'border-blue-500 bg-blue-50' : ''
                  } ${started ? 'border-gray-500 bg-gray-50' : ''}`}
                  style={{
                    // borderWidth: '1px',
                    // borderStyle: 'solid',
                    borderRadius: '10px',
                    // margin: '10px',
                  }}
                >
                  <div className="flex items-center space-x-5">
                    <div className="">
                      {/* {isSuccess ? (
                        <CheckCircleOutlined className='text-green-500 text-2xl' /> 
                      ):  (<ExclamationCircleOutlined className='text-red-500 h-8 w-8' />) } */}
                      {getStatusIcon(item.status)}
                    </div>

                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <p className="mb-0">{item?.title}</p>

                        <div className="text-xs text-gray-400 text-end">
                          {getFormattedDate(item?.timestamp)}
                        </div>
                      </div>
                      <p className="mt-1 mb-0 text-gray-800 font-semibold text-md">
                        {item?.message}
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex items-center gap-x-6">

                    <div className="">
                      {isSuccess ? (
                        <CheckCircleOutlined className='text-green-500 text-2xl' /> 
                      ):  (<ExclamationCircleOutlined className='text-red-500 h-8 w-8' />) }
                    </div>

                    <div className='flex gap-x-8 items-center'>
                      {item?.title}{' '}
                      <span className="text-xs text-gray-400 text-end">
                        {getFormattedDate(item?.timestamp)}
                      </span>
                    </div>

                  </div> */}
                  {item?.extraKeys
                    ? Object.keys(item?.extraKeys)?.map((objectKey, index) => (
                        <p key={index}>
                          <span className="text-gray-600">{objectKey}</span>:{' '}
                          {item?.extraKeys[objectKey]}
                        </p>
                      ))
                    : null}
                </div>
              );
            })}
          </div>
        ) : null}

        <div className="grid grid-cols-2 gap-6 mt-1">
          <button
            className="flex border-0 rounded-lg items-center justify-center gap-x-2.5 p-3 font-semibold bg-primary-500 hover:bg-primary-400 hover:text-white text-white cursor-pointer"
            onClick={handleClear}
          >
            {t('buttons.clear')}
          </button>
          <button
            className="flex rounded-lg items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-200 hover:text-black cursor-pointer"
            onClick={handleClose}
          >
            {t('buttons.close')}
          </button>
        </div>
      </div>
      {/* </div> */}
    </div>
  ) : null;
};

export default Notification;
